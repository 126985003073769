import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../../layout/Layout';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

const FieldStatusPage = () => {
  const location = useLocation();
  const [playerID, setPlayerID] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ready, setReady] = useState(false); // Indicates when to render content

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let urlPlayerID = queryParams.get('playerID');

    if (!urlPlayerID && location.search) {
      urlPlayerID = location.search.replace('?', ''); // Handle keyless query
    }

    if (urlPlayerID) {
      fetchPlayerStatus(urlPlayerID).finally(() => setReady(true));
    } else {
      setReady(true); // Ready to render the form
    }
  }, [location.search]);

  const fetchPlayerStatus = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('/api/FieldStatus/GetPlayerStatus', { playerID: id });
      setMessage(response.data.message);
    } catch (err) {
      setError('Unable to fetch the status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchPlayerStatus(playerID);
  };

  if (!ready) {
    // Prevent rendering until the URL is processed
    return null;
  }

  return (
    <Layout>
      <Card>
        <Card.Body className="field-status">
          <div className="row g-0 justify-content-center">
            <Card className="m-2 col-lg-5">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>Player Status</Card.Title>
              </Card.Header>
              <Card.Body>
                {message || error ? (
                  loading ? (
                    <p className="text-success mt-3">Fetching status...</p>
                  ) : error ? (
                    <p className="text-danger mt-3">{error}</p>
                  ) : (
                    <p className="text-success mt-3">{message}</p>
                  )
                ) : (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group
className="mb-3"
controlId="playerID"
                      >
                        <Form.Label>Enter Player ID</Form.Label>
                        <Form.Control
                          onChange={(e) => setPlayerID(e.target.value)}
                          placeholder="ex. 12345"
                          required
                          type="text"
                          value={playerID}
                        />
                      </Form.Group>
                      <Button
                        className="w-100"
                        disabled={loading || !playerID.trim()}
                        type="submit"
                      >
                        {loading ? 'Checking...' : 'Submit'}
                      </Button>
                    </Form>
                    {error && <p className="text-danger mt-3">{error}</p>}
                    {message && <p className="text-success mt-3">{message}</p>}
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </Card.Body>
      </Card>
    </Layout>
  );
};

export default FieldStatusPage;
